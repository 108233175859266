.App {
  background-color: #282c34;
  text-align: center;
}

.footerText {
  color: white;
  font-size: 1rem;
}

.chatbox {
  min-height: 100vh;
  min-width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chatbox-contents {
  width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  font-size: calc(0.625rem + 2vmin);
}

.chat-receiving {
  align-self: flex-start;
}

.chat-sending {
  align-self: flex-end;
}

.chatbox-move-up {
  position: relative;
  animation-name: move-up;
  animation-iteration-count: 1;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes move-up {
  0% { margin-bottom: 0rem }
  100% { margin-bottom: calc((2* 0.5rem + (0.625rem + 2vmin) + (2 * 0.75rem))) }
}

.chatbox-fade-in-move-up {
  position: absolute;
  animation-name: fade-in-move-up;
}

@keyframes fade-in-move-up {
  0% { opacity: 0%; bottom: calc(-2 * (2 * 0.5rem + (0.625rem + 2vmin) + (2 * 0.75rem))) }
  100% { opacity: 100%; bottom: calc(-1 * (2 * 0.5rem + (0.625rem + 2vmin) + (2 * 0.75rem))) }
}

.wait-for-dots-animation {
  animation-name: wait;
  animation-iteration-count: 1;
  animation-duration: 1.75s;
}
@keyframes wait {
  0% { opacity: 0% }
  100% { opacity: 100% }
}

.App-logo {
  width: 16rem;
  height: auto;
}
body {
    /* --bg-colour-a: #55CDFC; */
    --bg-colour-a: #EF7627;
    --bg-colour-b: white;
    /* --bg-colour-c: #F7A8B8; */
    --bg-colour-c: #B55690;
    --text-colour: #222222;
}

.receiving-1 {
    background-color: var(--bg-colour-a);
    border: 0.375rem solid var(--bg-colour-a);
}

.receiving-2 {
    background-color: var(--bg-colour-c);
    border: 0.375rem solid var(--bg-colour-c);
}

.sending {
    background-color: var(--bg-colour-b);
    border: 0.375rem solid var(--bg-colour-b);
}

.bubble-bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.3125rem 0.75rem;
    border-radius: 1.5625rem;
}

.bubble-text {
    color: var(--text-colour);
    position: relative;
    top: -0.0625rem;
}

.dot-jump-up {
    opacity: 0;
    position: relative;
    animation-name: jump-up;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes jump-up {
    0% { top: 1rem; opacity: 0 }
    50% { top: -1.5rem; opacity: 1 }
    100% { top: 0; opacity: 1 }
}
